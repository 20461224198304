import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';
import VendorLayout from 'components/VendorLayout';

const Index = () => {
  const router = useRouter();

  useEffect(() => {
    router.push('/member/profile');
  });

  return (
    <VendorLayout pageTitle="Home">
      <Box as="h1" textStyle="h1"></Box>
    </VendorLayout>
  );
};

export default Index;
